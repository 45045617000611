import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Helping a Wheelchair User to Board">
    <Hero title="Helping a Wheelchair User to Board" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard>
          <EmberCardSection noSeparator>
            <Stack>
              <Text>
                Wheelchair users board through the middle door (Door 2) via a
                lift that comes out of one of the steps. They can then manouevre
                into the wheelchair space where there are straps to secure the
                wheelchair. The wheelchair user should be facing forward and
                must be secured in position.
              </Text>
              <Text>
                Make sure you are fully familiar with the below steps for
                helping a wheelchair user to board. You should make sure to
                practice this regularly – for instance, try taking the lift out
                in the middle of a shift from time to time. If you have any
                questions then ask.
              </Text>
              <Text>
                If you have problems loading a wheelchair user, then phone the
                driver number and we will try to help you. If we can't, then we
                will work to find them alternative accessible transport. Under
                no circumstances drive without being confident that the
                passenger is secure.
              </Text>
              <Text>
                The process below may take a few minutes, which is likely to
                delay you. Make sure that the passenger is not made to feel that
                they are an inconvenience. Instead, we should welcome wheelchair
                users (whether or not they are pre-booked) and know that most
                other passengers will be fully understanding.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Step By Step Instructions">
          <EmberCardSection title="Step 1: Get in Position and Open Door 2">
            <Stack>
              <Text>
                You'll generally want to park close to the kerb, although there
                may be stops where it's better to park some way out because of a
                narrow footpath. The key thing is that the end of the wheelchair
                lift will end up resting on the pavement.
              </Text>
              <Text>Open the middle door (door 2).</Text>
            </Stack>
          </EmberCardSection>
          <EmberCardSection title="Step 2: Turn on the Power to the Lift">
            <Stack>
              <Text>
                Press the button circled below to power on the lift. If you
                forget to do this, the lift will not work.
              </Text>
              <StaticImage
                src="./images/power-button.png"
                alt="Wheelchair lift power button on dashboard"
                width={500}
              />
            </Stack>
          </EmberCardSection>
          <EmberCardSection title="Step 3: Take out the Controller">
            <Text>
              The controller is located on a mount at the rear door, beside the
              steps. On vehicles YD70 CHX and YD70 CHY, the controller for the
              lift is stored in the compartment above the entrance door. Take it
              out and walk to the middle door.
            </Text>
          </EmberCardSection>
          <EmberCardSection title="Step 4: Plug in the Controller">
            <Text>
              On most vehicles the handset will automatically be plugged in. On
              vehicles YD70 CHX and YD70 CHY, plug the controller in to the
              connector on the back of one of the steps at the middle door (on
              the left-hand side). Make sure to clip it in place securely.
            </Text>
          </EmberCardSection>
          <EmberCardSection title="Step 5: Extend the Lift">
            <Text>
              Press the out button on the controller to lower the lift (this is
              a downwards arrow). Keep going until it comes out fully. It may
              need a little nudge with your foot to get it all the way.
            </Text>
          </EmberCardSection>
          <EmberCardSection title="Step 6: Lift Barriers and Help the Passenger on to the Lift">
            <Text>
              The passenger should be loaded onto the lift with their back
              against the large plate, facing outwards from the bus. This is to
              ensure they do not fall backwards off the lift. Once the passenger
              is on the lift, make sure the barrier is closed behind them.
            </Text>
          </EmberCardSection>
          <EmberCardSection title="Step 7: Raise the Lift">
            <Text>
              Press the up button on the controller to raise the lift, until it
              is level with the floor of the coach. Ask the passenger to move
              into the wheelchair space, facing forward (or ask their companion
              to help if required).
            </Text>
          </EmberCardSection>
          <EmberCardSection title="Step 8: Stow the Lift Away">
            <Text>
              Once the passenger is fully in position, Lower the lift using the
              down button on the controller and fold the barriers away.
            </Text>
            <Text>
              Then press both the up and down arrows at the same time to stow
              the lift away. Keep pressing until it is all the way in
            </Text>
          </EmberCardSection>
          <EmberCardSection title="Step 9: Unplug the Controller">
            <Text>
              Unplug the controller and replace the cover on the socket. Replace
              it in the compartment above the front door.
            </Text>
          </EmberCardSection>
          <EmberCardSection title="Step 10: Secure the Wheelchair">
            <Stack>
              <Text>
                Use the straps in the wheelchair area to secure the wheelchair
                in place.
              </Text>
              <Text>
                Don't attach the straps to the wheels or the footplates as this
                won't be secure. Instead, have a look for anchor points which
                most wheelchairs now have. If not, look for any solid point on
                the chair (for example rear straps on horizontal bar beneath the
                seat, front straps on the post that the footrests are attached
                to). The person in the wheelchair will generally be able to
                advise. If the chair cannot be secured using the straps please
                call the driver line. Do not drive without the wheelchair
                secured, even if the passenger says it's ok – they may travel
                unsecured on a bus but those spaces are designed differently and
                there's less potential to fall down far.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
